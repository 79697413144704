/* Custom Fonmts */


@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}


/* Default CSS */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;

}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;

}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32 ease-in-out;
  background-color: #121212;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color:  #121212;
}

nav.navbar a.navbar-brand 
{
  width: 9%;
}

nav.navbar .navbar-nav .nav-link.navbar-link 
{
  font-weight: 400;
  color: #fff;
  letter-spacing:  0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link:active {
  color: #00d9ffc7 !important;
  text-shadow: 0 0 10px rgb(255, 255, 255);
  
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);

}  

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;

}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}



.navbar-text #contact {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

.ScrollLink {color: red;}



.footer-body{
  font-size: 30px;
}



.App {
  /* background-color: #000000; */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.card-body {
min-height: 150px;

}

.color-nav {
 background-color: #ece7e1;

}





/************ Skills Css ************/
.skill {
  margin-top: 30px;
  padding: 0 0 50px 0;
  position: relative;
  
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

.skill {
  margin-top: 30px;
  padding: 0 0 50px 0;
  position: relative;
  
}


.about-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}



#about {
  width: 40%;
  height: 50%;
  margin: 0 auto 15px auto;
  /* background: radial-gradient(circle at center, rgb(105, 160, 205), rgb(242, 10, 99) 84%);
  filter: contrast(132%) saturate(180%); */
  -moz-box-shadow: 0 0 2px #fff;
-webkit-box-shadow: 0 0 3px #fff;
box-shadow: 0px 0px 5px #fff;
filter: brightness(95%)saturate(60%);

}

/* #about > * {
  filter: opacity(60%) saturate(0%) sepia(90%);
} */


/************ Banner Css ************/
.banner {
  /* margin-top: 20px;
  padding: 30px 0 0 0; */
  background-image:
  linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.73)),
  url('./assets/img/galaxy.jpg');
  
  background-size: cover;
  background-color: black !important;
 
  padding: 20px;
  z-index: 5;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

 
  /* background-size: 30%; */
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  animation-name : mymove;
  animation-iteration-count : infinite;
  animation-direction : alternate;
  animation-duration : 2s;

  
}

.imgOverlay {
  width: 80%;
  height: 100%;
  padding: 6px;
  background-color: rgba(18, 17, 17, 0.5);
 }


.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(5, 252, 219, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 36px;
  display: inline-block;
}
.banner h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 40px;
  display: block;
}
.banner p {
  color: #ffffff;
  font-size: 21px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  background-color: #1b1b1b !important;
  border-color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 70px;
  letter-spacing: 0.8px;
  display: block;
  align-items: center;
  padding: 7px;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
 
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}



/* cards */


.card {
  margin-bottom: 4rem;
  background-color: rgb(247, 247, 247);
  height: 100%;
}

.proj-pic {
max-height: 225.16px;
border-radius: 50%;
color: #666;
transition: .5s;

}


.proj-pic::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 150%;
  background: #10c7ff;
  transition: .5s;
  transform: scale(.9);
  z-index: -1;
}

.proj-pic::before {
  transform: scale(1.1);
  box-shadow: 0 0 30px #10c7ff;
}

.proj-pic:hover {
  color: #10c7ff;
  box-shadow: 0 0 30px #10c7ff;
  text-shadow: 0 0 30px #10c7ff;
}

.project-bx {
/* z-index: 40; */
background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;

}


.project h2 {
	font-size: 45px;
	font-weight: 700;
}



.project p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
  }


a {
  text-decoration: none !important;
  color:#fff;
}


.proj-txtx {
  min-height: 94x;
}


#tsparticles {
  position:fixed;
z-index: -10

}

@media only screen and (max-width: 890px) {
  .banner img{
    width: 80%;
  }
}

@media only screen and (max-width: 991px) {
  .banner button{
    margin-top: 120px;
  }
}

@media only screen and (max-width: 991px) {
  #about{
    width: 100%;
    height: 100%;
  }
}
